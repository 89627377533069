/* eslint-disable */
require('./afd-logo')
require('./ano-2011-logo')
require('./bdg-logo')
require('./bg')
require('./bsw-logo')
require('./cdu-logo')
require('./ciu-logo')
require('./diem25-logo')
require('./ecalling-logo')
require('./epp-logo')
require('./euromat-logo')
require('./european-left-logo')
require('./european-spring-logo')
require('./european-stars')
require('./fdp-logo')
require('./foederalist-logo')
require('./hertie-logo')
require('./jef-logo')
require('./lin-logo')
require('./linke-logo')
require('./Logo_JEF')
require('./neos-logo')
require('./ovld-logo')
require('./pes-logo')
require('./piraten-logo')
require('./pirates-logo')
require('./poe-colored-logo')
require('./poe-logo-alt')
require('./poe-logo-bg')
require('./poe-logo')
require('./polis-colored-logo')
require('./polis-logo')
require('./spd-logo')
require('./star')
require('./tierschutzpartei-logo')
require('./twfe-logo')
