/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bg': {
    width: 1280,
    height: 1080,
    viewBox: '0 0 1280 1080',
    data: '<defs><path pid="0" id="svgicon_bg_a" d="M134.856 0h1280v1080h-1280z"/></defs><g transform="translate(-134.856)" _fill="none" fill-rule="evenodd"><mask id="svgicon_bg_b" _fill="#fff"><use xlink:href="#svgicon_bg_a"/></mask><use fill-opacity="0" _fill="#164194" xlink:href="#svgicon_bg_a"/><g opacity=".501" mask="url(#svgicon_bg_b)" _stroke="#3CAAE6" stroke-width="2"><g transform="rotate(-26 2187.654 -1447.119)"><circle pid="1" cx="468" cy="154" r="57.27"/><circle pid="2" cx="468" cy="154" r="98.892"/><circle pid="3" cx="468" cy="154" r="153"/><circle pid="4" cx="229" cy="308" r="57.27"/><circle pid="5" cx="229" cy="308" r="98.892"/><circle pid="6" cx="229" cy="308" r="153"/><circle pid="7" cx="228.5" cy="308.5" r="57.5"/><circle pid="8" cx="229" cy="308" r="99"/><circle pid="9" cx="229" cy="308" r="153"/><circle pid="10" cx="228.5" cy="308.5" r="169.5"/><circle pid="11" cx="229" cy="308" r="228"/></g></g><g opacity=".501" mask="url(#svgicon_bg_b)" _stroke="#3CAAE6" stroke-width="2"><g transform="translate(0 164.88)"><circle pid="12" cx="154" cy="154" r="57.27"/><circle pid="13" cx="154" cy="154" r="98.892"/><circle pid="14" cx="154" cy="154" r="153"/></g></g></g>'
  }
})
