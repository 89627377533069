<template>
  <section>
    <h1>{{ $t('about.headline') }}</h1>

    <div class="about-content">
      <div class="about-text">
        <v-markdown :source="$t('about.content')" />
      </div>

      <div class="about-people">
        <div v-for="team of teamLabels" :key="team.label" :class="`people-group ${team.token}`">
          <div class="group-inner" style="flex-direction: column">
            <a :href="team.url" target="_blank" rel="noopener noreferrer"
              style="flex: 1, display: flex !important; justify-content: center;">
              <img v-if="team.logo" :class="`team-logo ${team.token}`" :src="team.logo" :alt="team.label"
                height="100" />
              <span :hidden="team.logo !== false">{{ team.label }}</span>
            </a>
            <p style="margin-top: 24px">{{ team.text[$i18n.locale] }} </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <h1 style="margin-top: 48px">{{ $t('about.support_title') }}</h1>

    <div class="about-people-wrapper">
      <p>
        {{ $t('about.special_thanks') }}
      </p>
    <div class="about-people">
      <div v-for="team of supportLabels" :key="team.label" :class="`people-group ${team.token}`">
        <div class="group-inner" style="flex-direction: column">
          <a :href="team.url" target="_blank" rel="noopener noreferrer"
            style="flex: 1, display: flex !important; justify-content: center;">
            <img v-if="team.logo" :class="`team-logo ${team.token}`" :src="team.logo" :alt="team.label" height="100" />
            <span :hidden="team.logo !== false">{{ team.label }}</span>
          </a>
          <p v-if="team.text" class="team-text">{{ team.text }} </p>
        </div>
      </div>
    </div>
    </div> -->

    <!-- <div class="about-people-wrapper">
      <p>{{ $t('about.support_text') }}</p>

      <div class="about-people">
        <div v-for="team of variousLabels" :key="team.label" :class="`people-group ${team.token}`">
          <div class="group-inner" style="flex-direction: column">
            <a :href="team.url" target="_blank" rel="noopener noreferrer"
              style="flex: 1, display: flex !important; justify-content: center;">
              <img v-if="team.logo" :class="`team-logo ${team.token}`" :src="team.logo" :alt="team.label"
                height="100" />
              <span :hidden="team.logo !== false">{{ team.label }}</span>
            </a>
            <p v-if="team.text" class="team-text">{{ team.text }} </p>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import '@/assets/icons'
export default {
  name: 'About',

  data() {
    return {
      members: {
        dev: [{ name: 'Moritz Kröger', profile: 'http://moritz.berlin' }],
      },
      teamLabels: [
        {
          token: 'poe',
          label: 'Pulse of Europe',
          logo: require('@/assets/svg/poe-logo-bg.svg'),
          url: 'https://pulseofeurope.eu',
          text: {
            de: 'Pulse of Europe ist eine unabhängige und überparteiliche Bürgerinitiative, die europäische Öffentlichkeit in vielfachen Formaten und Projekten wirksam macht.',
            en: 'Pulse of Europe is an independent and non-partisan citizens’ initiative that makes the European public sphere effective in a variety of formats and projects.',
            fi: 'Pulse of Europe is an independent and non-partisan citizens’ initiative that makes the European public sphere effective in a variety of formats and projects.',
          },
        },

        {
          token: 'foederalist',
          label: 'Der (europäische) Föderalist',
          logo: require('@/assets/svg/foederalist-logo.svg'),
          url: 'https://www.foederalist.eu',
          text: {
            de: '“Der (europäische) Föderalist” ist ein von dem Politikwissenschaftler Manuel Müller betriebenes Blog über europäische Verfassungspolitik und supranationale Demokratie.',
            en: '“Der (europäische) Föderalist” is a blog on European constitutional politics and supranational democracy, run by political scientist Manuel Müller.',
            fi: '“Der (europäische) Föderalist” is a blog on European constitutional politics and supranational democracy, run by political scientist Manuel Müller.',
          },
        },

        {
          token: 'polis',
          label: 'Polis180',
          logo: require('@/assets/svg/polis-colored-logo.svg'),
          url: 'https://polis180.org/',
          text: {
            de: 'Polis180 ist ein Graswurzel-Thinktank, der junge Perspektiven in die Außen- und Europapolitik einbringt.',
            en: 'Polis180 is a grassroots think tank that brings young perspectives to foreign and European policy.',
            fi: 'Polis180 is a Berlin-based grassroots think tank on foreign and European policy.',
          },
        },

        {
          token: 'jef',
          label: 'JEF',
          logo: require('@/assets/svg/Logo_JEF.svg'),
          url: 'https://www.jef.de/',
          text: {
            de: 'Seit 1949 setzen sich die Jungen Europäischen Föderalist:innen als Anwalt der europäischen Idee für die Vereinigten Staaten von Europa und eine bessere Europapolitik ein, stärken mit Bildungsarbeit und internationalen Begegnungen demokratische Werte und fördern die Beteiligung junger Menschen in ihrem europaweiten Jugendverband.',
            en: 'Since 1949, the Young European Federalists have been advocating the European idea of a United States of Europe and a better European policy, strengthening democratic values through educational work and international encounters and promoting the participation of young people in their Europe-wide youth association.'
          }
        },
      ],
      supportLabels: [

        // {
        //   token: 'tpeuropa',
        //   label: 'Treffpunkt Europa',
        //   logo: require('@/assets/tpeuropa-logo.png'),
        //   url: 'https://www.treffpunkteuropa.de/',
        // },
      ],
      variousLabels: [
        // {
        //   token: 'ecalling',
        //   label: 'Europe Calling',
        //   logo: require('@/assets/svg/ecalling-logo.svg'),
        //   url: 'https://europe-calling.de/',
        // },
        // {
        //   token: 'twfe',
        //   label: 'Tu was für Europa',
        //   logo: require('@/assets/svg/twfe-logo.svg'),
        //   url: 'https://tu-was-fuer-europa.de',
        // },
        // {
        //   token: 'jefd',
        //   label: 'Junge Europäische Föderalist:innen Deutschland',
        //   logo: require('@/assets/jefd-logo.png'),
        //   url: 'https://www.jef.de/',
        // },
        // {
        //   token: 'eude',
        //   label: 'Europa Union Deutschland',
        //   logo: require('@/assets/eude-logo.png'),
        //   url: 'https://www.europa-union.de/',
        // },
        // {
        //   token: 'eubde',
        //   label: 'Europäische Bewegung Deutschland',
        //   logo: require('@/assets/eubde-logo.png'),
        //   url: 'https://www.netzwerk-ebd.de/',
        // },
      ],
    }
  },
}
</script>

<style lang="postcss" scoped>
h1,
p {
  margin-bottom: var(--base-gap);
  color: var(--white);
}

.team-logo.poe {
  width: 100%;
  height: auto;
}

.about-content {
  display: flex;
  flex-direction: column;
}

.about-text {
  margin-bottom: var(--base-gap);
}

.about-people {
  display: grid;
  grid-gap: var(--base-gap);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;

  & .people-group {
    text-align: center;
  }

  & h2 {
    color: var(--prussian-blue);
  }

  & ul {
    list-style: none;
  }

  & svg:not(.team-logo) {
    stroke: var(--white);
    width: 1em;
    height: 1em;
  }

  & img {
    max-height: 100%;
    width: 100%;

  }
}

.about-people-wrapper {
  margin-top: 2rem;

  p {
    margin-bottom: 1rem;
  }
}

.group-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-radius: var(--border-radius);
  padding: var(--base-gap);
  color: var(--prussian-blue);

  &.dark {
    background: var(--prussian-blue);
    flex-direction: column;

    & h2,
    & a,
    & span {
      color: var(--white);
    }
  }

  & a,
  & span {
    color: var(--prussian-blue);
    font-weight: 700;
    width: 220px;
    height: 100px;
  }

  & a:hover {
    color: var(--orange);
  }
}

.team-logo {
  max-height: 50px;
  object-fit: contain;

}

.list-wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;

  @media (max-width: 576px) {
    flex-flow: row wrap;
  }
}

@media (max-width: 630px) {
  .about-people {
    /* flex-direction: column;
    align-items: center;
    justify-content: flex-start; */
    grid-template-columns: 1fr;
  }

  .people-group {
    flex: 0 0 100%;
    width: 100%;
  }

  .group-inner {
    width: 100%;
  }

  .team-text {
    margin-top: 24px;

    @media (max-width: 576px) {
      margin-bottom: 0;
    }
  }
}
</style>
