/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lin-logo': {
    width: 16,
    height: 16,
    viewBox: '0 0 566.93 127.057',
    data: '<path pid="0" d="M547.424 127.057c9.143 0 17.836-7.231 19.285-16.341 1.54-9.688-5.171-16.772-14.602-16.772-8.563 0-17.159 7.519-18.562 16.34-1.471 9.254 4.59 16.773 13.879 16.773zm-80.358-1.591h52.247l3.889-25.738h-24.127l2.512-16.629h21.645l3.888-25.736h-21.646l2.293-15.185h24.126l3.888-25.736H483.53l-16.464 109.024zm-5.44 0l-16.573-56.393 30.42-52.632h-29.64l-22.449 46.415 6.87-46.415h-28.122l-16.465 109.024h28.125l7.554-50.029 10.091 50.029h30.189zm-160.442 0h27.021l4.825-31.955c1.551-10.269 1.838-20.389 2.699-30.654l11.776 62.609h27.02L390.99 16.442h-27.021l-4.192 27.761c-1.899 12.579-1.711 25.013-2.508 37.594l-12.875-65.355h-26.745l-16.465 109.024zm-49.62 0l3.885-25.738h-21.093l12.578-83.286h-28.123l-16.465 109.024h49.218zm-132.041 0h52.249l3.887-25.738h-24.125l2.511-16.629h21.644l3.887-25.736H157.93l2.293-15.185h24.125l3.888-25.736h-52.249l-16.464 109.024zm-11.196 0L124.79 16.441H96.667L80.203 125.466h28.124zM40.484 43.625c12.82 0 12.538 16.483 10.748 28.34-1.55 10.266-6.29 27.039-19.133 27.183l8.385-55.523zM0 125.466h24.541c17.094 0 47.985-4.627 55.584-54.947 4.977-32.967-9.067-54.077-41.05-54.077h-22.61L0 125.466zm289.809 0l13.871-89.067h-28.123l-13.873 89.067h28.125z"/><path pid="1" _fill="#DC0000" d="M309.336 0l-54.315 16.417 49.508 14.552z"/>'
  }
})
